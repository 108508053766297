import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Stack, TextField } from '@mui/material';
import Popup from '../../../components/Popup';
import { BlueButton } from '../../../utils/buttonStyles';
import { useDispatch, useSelector } from 'react-redux';
import { addStuff } from '../../../redux/userHandle';
import styled from 'styled-components';
import altImage from "../../../assets/altimg.png";

const AddProduct = () => {
  const dispatch = useDispatch();
  const { currentUser, status, response, error } = useSelector(state => state.user);

  const [productName, setProductName] = useState("");
  const [mrp, setMrp] = useState("");
  const [cost, setCost] = useState("");
  const [discountPercent, setDiscountPercent] = useState("");
  const [subcategory, setSubcategory] = useState("");
  const [productImage, setProductImage] = useState(null); // Updated to store the uploaded image file
  const [imagePreview, setImagePreview] = useState(altImage); // Default image for preview
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [tagline, setTagline] = useState("");
  const [quantity, setQuantity] = useState(1);
  const seller = currentUser.id;

  const [loader, setLoader] = useState(false);
  const [message, setMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);

  // Separate handler for image upload
  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    console.log(file);
    
    if (file) {
      setProductImage(file);
      setImagePreview(URL.createObjectURL(file)); // Create a local URL for the image preview

      setLoader(true);
      const formData = new FormData();
      formData.append('productImage', file);

      // Dispatch image upload action
      const imageResponse = await dispatch(addStuff("ProductImageUpload", formData));

      if (imageResponse?.payload?.imagePath) {
        setProductImage(imageResponse.payload.imagePath); // Store the image path for later
      }
      console.log(productImage);
      
      setLoader(false);
    }
  };

  // Main submit handler for product data
  const submitHandler = (event) => {
    event.preventDefault();
    setLoader(true);

    // Check if productImage is uploaded
    if (!productImage) {
      setLoader(false);
      setMessage("Please upload an image before submitting the product data.");
      setShowPopup(true);
      return;
    }

    const formData = new FormData();
    formData.append('productName', productName);
    formData.append('priceMrp', mrp);
    formData.append('priceCost', cost);
    formData.append('discountPercent', discountPercent);
    formData.append('subcategory', subcategory);
    formData.append('productImage', productImage);  // Store the image path received from the server
    formData.append('category', category);
    formData.append('description', description);
    formData.append('tagline', tagline);
    formData.append('quantity', quantity);
    formData.append('seller', seller);

    dispatch(addStuff("ProductCreate", formData));
  };

  useEffect(() => {
    if (status === "added") {
      setLoader(false);
      setShowPopup(true);
      setMessage("Done Successfully");
    } else if (status === 'failed') {
      setMessage(response);
      setShowPopup(true);
      setLoader(false);
    } else if (status === 'error') {
      setLoader(false);
      setMessage("Network Error");
      setShowPopup(true);
    }
  }, [status, response, error]);

  return (
    <>
      <Box sx={{ flex: '1 1 auto', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ maxWidth: 550, px: 3, py: '30px', width: '100%' }}>
          <div>
            <Stack spacing={1} sx={{ mb: 3 }}>
              <ProductImage src={imagePreview} alt="Product" />
            </Stack>
            <form onSubmit={submitHandler}>
              <Stack spacing={3}>
                <input type="file" accept="image/*" onChange={handleImageUpload} required />
                <TextField fullWidth label="Product Name" value={productName} onChange={(event) => setProductName(event.target.value)} required InputLabelProps={{ shrink: true }} />
                <TextField fullWidth multiline label="Description" value={description} onChange={(event) => setDescription(event.target.value)} required InputLabelProps={{ shrink: true }} />
                <TextField fullWidth label="MRP" value={mrp} onChange={(event) => setMrp(event.target.value)} required InputLabelProps={{ shrink: true }} />
                <TextField fullWidth label="Cost" value={cost} onChange={(event) => setCost(event.target.value)} required InputLabelProps={{ shrink: true }} />
                <TextField fullWidth label="Discount Percent" value={discountPercent} onChange={(event) => setDiscountPercent(event.target.value)} required InputLabelProps={{ shrink: true }} />
                <TextField fullWidth label="Category" value={category} onChange={(event) => setCategory(event.target.value)} required InputLabelProps={{ shrink: true }} />
                <TextField fullWidth label="Subcategory" value={subcategory} onChange={(event) => setSubcategory(event.target.value)} required InputLabelProps={{ shrink: true }} />
                <TextField fullWidth label="Tagline" value={tagline} onChange={(event) => setTagline(event.target.value)} required InputLabelProps={{ shrink: true }} />
                <TextField fullWidth label="Quantity" type="number" value={quantity} onChange={(event) => setQuantity(event.target.value)} required InputLabelProps={{ shrink: true }} />
              </Stack>
              <BlueButton fullWidth size="large" sx={{ mt: 3 }} variant="contained" type="submit" disabled={loader}>
                {loader ? <CircularProgress size={24} color="inherit" /> : "Add"}
              </BlueButton>
            </form>
          </div>
        </Box>
      </Box>
      <Popup message={message} setShowPopup={setShowPopup} showPopup={showPopup} />
    </>
  );
};

export default AddProduct;

// Styled component for product image
const ProductImage = styled.img`
  width: 200px;
  height: auto;
  margin-bottom: 8px;
`;
