import axios from 'axios';

import {
    authRequest,
    authSuccess,
    authFailed,
    authError,
    stuffAdded,
    getDeleteSuccess,
    getRequest,
    getFailed,
    getError,
    productSuccess,
    productDetailsSuccess,
    getProductDetailsFailed,
    getProductsFailed,
    setFilteredProducts,
    getSearchFailed,
    sellerProductSuccess,
    getSellerProductsFailed,
    stuffUpdated,
    updateFailed,
    getCustomersListFailed,
    customersListSuccess,
    getSpecificProductsFailed,
    specificProductSuccess,
    updateCurrentUser,
} from './userSlice';

export const authUser = (fields, role, mode) => async (dispatch) => {
    dispatch(authRequest());

    try {
        const result = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/${role}${mode}`, fields, {
            headers: { 'Content-Type': 'application/json' },
        });
        if (result.data.role) {
            dispatch(authSuccess(result.data));
        }
        else {
            dispatch(authFailed(result.data.message));
        }
    } catch (error) {
        dispatch(authError(error));
    }
};

export const addStuff = (address, fields) => async (dispatch) => {
    dispatch(authRequest());

    try {
        // Send the POST request without the unnecessary 'body' property
        const result = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/${address}`, fields, {
            // headers: { 'Content-Type': 'application/json' },
        });

        // Check if the response contains a message indicating failure
        if (result.data.message) {
            dispatch(authFailed(result.data.message)); // Dispatch failure with error message
        } else {
            dispatch(stuffAdded());  // Dispatch success action to update the state
            dispatch({ type: 'SET_ORDER_STATUS', payload: 'added' }); // Optional: Ensure this is dispatched as well
        }
    } catch (error) {
        console.error("Error in addstuff:", error)
        dispatch(authError(error.message || 'An error occured')); // Dispatch error if the request fails
    }
};

export const updateStuff = (fields, id, address) => async (dispatch) => {

    try {
        const result = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/${address}/${id}`, fields, {
            headers: { 'Content-Type': 'application/json' },
        });
        if (result.data.message) {
            dispatch(updateFailed(result.data.message));
        }
        else {
            dispatch(stuffUpdated());
        }

    } catch (error) {
        dispatch(getError(error));
    }
}

export const deleteStuff = (id, address) => async (dispatch) => {
    dispatch(getRequest());

    try {
        const result = await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/${address}/${id}`);
        if (result.data.message) {
            dispatch(getFailed(result.data.message));
        } else {
            dispatch(getDeleteSuccess());
        }
    } catch (error) {
        dispatch(getError(error));
    }
}

export const updateCustomer = (fields, id) => async (dispatch) => {
    // Validate customer ID
    if (!id) {
        console.error("Customer ID is missing. Cannot update customer.");
        return;
    }

    // Update the Redux store
    dispatch(updateCurrentUser(fields));

    // Remove sensitive fields
    const newFields = { ...fields };
    delete newFields.token;

    try {
        // API call to update customer details
        await axios.put(`${process.env.REACT_APP_BASE_URL}/api/CustomerUpdate/${id}`, newFields, {
            headers: { 'Content-Type': 'application/json' },
        });

        // Dispatch success action
        dispatch(stuffUpdated());
    } catch (error) {
        // Handle error gracefully
        console.error("Error updating customer:", error);
        dispatch(getError(error));
    }
};

export const getProductsbySeller = (id) => async (dispatch) => {
    dispatch(getRequest());

    try {
        const result = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/getSellerProducts/${id}`);
        if (result.data.message) {
            dispatch(getSellerProductsFailed(result.data.message));
        }
        else {
            dispatch(sellerProductSuccess(result.data));
        }
    } catch (error) {
        dispatch(getError(error));
    }
}

export const getProducts = () => async (dispatch) => {
    dispatch(getRequest());

    try {
        const result = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/getProducts`);
        if (result.data.message) {
            dispatch(getProductsFailed(result.data.message));
        }
        else {
            dispatch(productSuccess(result.data));
        }
    } catch (error) {
        dispatch(getError(error));
    }
}

export const getProductDetails = (id) => async (dispatch) => {
    dispatch(getRequest());

    try {
        const result = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/getProductDetail/${id}`);
        if (result.data.message) {
            dispatch(getProductDetailsFailed(result.data.message));
        }
        else {
            dispatch(productDetailsSuccess(result.data));
        }

    } catch (error) {
        dispatch(getError(error));
    }
}

export const getCustomers = (id, address) => async (dispatch) => {
    dispatch(getRequest());

    try {
        const result = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/${address}/${id}`);
        if (result.data.message) {
            dispatch(getCustomersListFailed(result.data.message));
        }
        else {
            dispatch(customersListSuccess(result.data));
        }

    } catch (error) {
        dispatch(getError(error));
    }
}

export const getSpecificProducts = (id, address) => async (dispatch) => {
    dispatch(getRequest());
    try {
        const result = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/${address}/${id}`);
        if (result.data.message) {
            dispatch(getSpecificProductsFailed(result.data.message));
        }
        else {
            dispatch(specificProductSuccess(result.data));
        }

    } catch (error) {
        dispatch(getError(error));
    }
}

export const getSearchedProducts = (address, key) => async (dispatch) => {
    dispatch(getRequest());

    try {
        const result = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/${address}/${key}`);
        if (result.data.message) {
            dispatch(getSearchFailed(result.data.message));
        }
        else {
            dispatch(setFilteredProducts(result.data));
        }

    } catch (error) {
        dispatch(getError(error));
    }
}

export const updateProductRemarks = (productId, remarks) => async (dispatch) => {
    try {
        const { data } = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/getOrderedProductsBySeller/${productId}`, { remarks });

        dispatch({
            type: 'UPDATE_PRODUCT_REMARKS_SUCCESS',
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: 'UPDATE_PRODUCT_REMARKS_FAIL',
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        });
    }
};

export const updateProductStatus = (sellerID, orderId, status) => async (dispatch) => {
    // Check if sellerID, orderId, and status are provided
    if (!sellerID || !orderId || !status) {
        console.error("Seller ID, Order ID, and action (status) are required");
        return;
    }

    try {
        // POST request to update order status
        const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/updateOrderStatus/${sellerID}`,
            { orderId, action: status }  // Correct payload
        );

        // Dispatch success action with the response data
        dispatch({
            type: 'UPDATE_PRODUCT_STATUS_SUCCESS',
            payload: response.data,
        });

        // Optionally, you can return the response or success message
        alert(`Order has been marked as ${status}.`);
    } catch (error) {
        // Dispatch failure action with the error message
        dispatch({
            type: 'UPDATE_PRODUCT_STATUS_FAILURE',
            payload: error.response?.data?.message || error.message,
        });

        // Log detailed error for debugging
        console.error(`Error updating order status:`, error.response?.data || error.message);
    }
};
