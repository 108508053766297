import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Dialog, DialogContent, DialogTitle, DialogActions } from "@mui/material";
import { BlueButton, GreenButton, RedButton } from "../../../utils/buttonStyles";
import TableTemplate from "../../../components/TableTemplate";
import { useNavigate } from "react-router-dom";
import { getSpecificProducts, updateProductRemarks, updateProductStatus } from "../../../redux/userHandle";

const OutForDeliverySection = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { currentUser, specificProductData, responseSpecificProducts } = useSelector(state => state.user);

    const [openDialog, setOpenDialog] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [remarks, setRemarks] = useState("");
    
    const [openCustomerDialog, setOpenCustomerDialog] = useState(false);
    const [customerDetails, setCustomerDetails] = useState(null);

    // Fetch the specific products when currentUser is available
    useEffect(() => {
        if (currentUser && currentUser.id) {
            dispatch(getSpecificProducts(currentUser.id, "getOrderedProductsBySeller"));
        }
    }, [dispatch, currentUser]);

    // Open the dialog to update remarks
    const handleOpenDialog = (product) => {
        setSelectedProduct(product);
        setRemarks(product.remarks || "");
        setOpenDialog(true);
    };

    // Close the remarks dialog
    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedProduct(null);
        setRemarks("");
    };

    // Save the updated remarks
    const handleSaveRemarks = () => {
        if (selectedProduct) {
            dispatch(updateProductRemarks(selectedProduct.productID, remarks)); 
        }
        handleCloseDialog();
    };

    // Update product status (Complete, Cancel)
    const handleUpdateStatus = (orderId, action) => {
        if (!orderId || !action) {
            console.error("Order ID and action are required");
            return;
        }
    
        if (currentUser && currentUser.id) {
            dispatch(updateProductStatus(currentUser.id, orderId, action))
                .catch(error => console.error("Error updating product status:", error));
        } else {
            console.error("Current user (seller) ID not found.");
        }
    };

    // Handle view customer details
    const handleViewCustomerDetails = (row) => {
        const customerInfo = {
            name: row.customer || "N/A", 
            email: row.customerEmail || "N/A",
            shippingAddress: row.shippingAddress || "N/A",
            phone: row.phone || "N/A",
        };
        setCustomerDetails(customerInfo);
        setOpenCustomerDialog(true);
    };

    // Close customer dialog
    const handleCloseCustomerDialog = () => {
        setOpenCustomerDialog(false);
        setCustomerDetails(null);
    };

    // Define columns for product table
    const productsColumns = [
        { id: 'customer', label: 'Customer', minWidth: 170 },
        { id: 'name', label: 'Product Name', minWidth: 170 },
        { id: 'quantity', label: 'Product Quantity', minWidth: 100 },
        { id: 'category', label: 'Product Category', minWidth: 100 },
        { id: 'subcategory', label: 'Product SubCategory', minWidth: 100 },
        { id: 'remarks', label: 'Remarks', minWidth: 200 }, 
    ];

    // Map over specificProductData to generate rows for the table
    const productsRows = Array.isArray(specificProductData) && specificProductData.length > 0
        ? specificProductData.flatMap(order => 
            order.products.map(product => ({
                key: `${order.orderId}-${product.productId}`,
                customer: order.customer.name || "Unknown customer",
                name: product.productName,
                quantity: product.quantity,
                category: product.category || "N/A", // Use a fallback if category is missing
                subcategory: product.subcategory || "N/A", // Use a fallback if subcategory is missing
                remarks: order.remarks || "No remarks", 
                productID: product.productId || "Unknown product ID",
                orderId: order.orderId,
                orderStatus: order.orderStatus,
                isCompleted: order.isCompleted,
                isCancelled: order.isCancelled,
                customerName: order.customer.name, 
                customerEmail: order.customer.email, 
                shippingAddress: order.shippingData.address || "N/A", 
                phone: order.shippingData.phoneNo || "N/A"
            }))
        )
        : [];


    // Button component for each row (View Product, View Customer, Update Remarks, Complete, Cancel)
    const ProductsButtonHaver = ({ row }) => {
        return (
            <>
                <BlueButton
                    onClick={() => navigate("/Seller/orders/product/" + row.productID)}
                >
                    View Product
                </BlueButton>
                <BlueButton
                    onClick={() => handleViewCustomerDetails(row)}
                    key={`${row.productID}-viewCustomer`}
                >
                    View Customer
                </BlueButton>
                <GreenButton
                    onClick={() => handleUpdateStatus(row.orderId, 'complete')}
                    key={`${row.productID}-complete`}
                >
                    Mark as Complete
                </GreenButton>
                <RedButton
                    onClick={() => handleUpdateStatus(row.orderId, 'cancel')}
                    key={`${row.productID}-cancel`}
                >
                    Cancel Order
                </RedButton>
            </>
        );
    };

    return (
        <>
            {responseSpecificProducts ?
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
                    <GreenButton
                        variant="contained"
                        onClick={() => navigate("/Seller/addproduct")}
                    >
                        Add Products
                    </GreenButton>
                </Box>
                :
                <>
                    <Typography variant="h5" gutterBottom>
                        Products List:
                    </Typography>

                    <TableTemplate buttonHaver={ProductsButtonHaver} columns={productsColumns} rows={productsRows} />
                </>
            }

            {/* Dialog for viewing customer details */}
            <Dialog open={openCustomerDialog} onClose={handleCloseCustomerDialog}>
                <DialogTitle>Customer Details</DialogTitle>
                <DialogContent>
                    {customerDetails && (
                        <>
                            <Typography variant="body1"><strong>Name:</strong> {customerDetails.name}</Typography>
                            <Typography variant="body1"><strong>Email:</strong> {customerDetails.email}</Typography>
                            <Typography variant="body1"><strong>Phone:</strong> {customerDetails.phone}</Typography>
                            <Typography variant="body1"><strong>Shipping Address:</strong> {customerDetails.shippingAddress}</Typography>
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <RedButton onClick={handleCloseCustomerDialog}>Close</RedButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default OutForDeliverySection;
