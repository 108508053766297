import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Box, Card, IconButton, Menu, MenuItem, Rating, TextField, Typography } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import { addToCart, underControl } from '../../../redux/userSlice';
import { BasicButton, GreenButton } from '../../../utils/buttonStyles';
import { getProductDetails, updateStuff } from '../../../redux/userHandle';
import Popup from '../../../components/Popup';
import { generateRandomColor, timeAgo } from '../../../utils/helperFunctions';
import { useTranslation } from 'react-i18next';

const ViewOrder = () => {
    const { t } = useTranslation(); 
    const dispatch = useDispatch();
    const {id} = useParams();
    const productID = id;

    const { currentUser, currentRole, productDetails, loading, status, error, responseReview, responseDetails } = useSelector(state => state.user);

    useEffect(() => {
        dispatch(getProductDetails(productID));
    }, [productID, dispatch]);

    const [rating, setRating] = useState(0);
    const [comment, setComment] = useState('');

    const [message, setMessage] = useState("");
    const [showPopup, setShowPopup] = useState(false);

    const [anchorElMenu, setAnchorElMenu] = useState(null);

    const handleOpenMenu = (event) => {
        setAnchorElMenu(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorElMenu(null);
    };

    const handleRatingChange = (event, newRating) => {
        setRating(newRating);
    };

    const deleteHandler = (reviewId) => {
        const fields = { reviewId };

        dispatch(updateStuff(fields, productID, "deleteProductReview"));
    };

    const reviewer = currentUser && currentUser.id

    const handleSubmit = (event) => {
        event.preventDefault();

        if (rating === 0) {
            setMessage(t("cart.selectRating"));
            setShowPopup(true);
        } else {
            const fields = { rating, comment, reviewer };
            dispatch(updateStuff(fields, productID, "addReview"));
            setRating(0);
            setComment('');
        }
    };

    useEffect(() => {
        if (status === "updated") {
            dispatch(getProductDetails(productID));
            dispatch(underControl());
        } else if (responseReview) {
            setMessage(t("cartFunction.alreadySubmit"));
            setShowPopup(true);
        } else if (error) {
            setMessage("Network Error");
            setShowPopup(true);
        }
    }, [dispatch, responseReview, productID, status, error]);

    return (
        <>
            {loading ?
                <div>{t("cartFunction.loading")}</div>
                :
                <>
                    {
                        responseDetails ?
                            <div>{t("cartFunction.productNotFound")}</div>
                            :
                            <>
                                <ProductContainer>
                                    <ProductImage src={productDetails && productDetails.productImage} alt={productDetails && productDetails.productName} />
                                    <ProductInfo>
                                        <ProductName>{productDetails && productDetails.productName}</ProductName>
                                        <PriceContainer>
                                            <PriceCost>Rs.{productDetails && productDetails.priceCost}</PriceCost>
                                            <PriceMrp>Rs.{productDetails && productDetails.priceMrp}</PriceMrp>
                                            <PriceDiscount>{productDetails && productDetails.discountPercent}% off</PriceDiscount>
                                        </PriceContainer>
                                        <Description>{productDetails && productDetails.description}</Description>
                                        <ProductDetails>
                                            <p>{t("cartFunction.category")}: {productDetails && productDetails.category}</p>
                                            <p>{t("cartFunction.subCategory")}: {productDetails && productDetails.subcategory}</p>
                                        </ProductDetails>
                                    </ProductInfo>
                                </ProductContainer>

                                {
                                    currentRole === "Customer" &&
                                    <>
                                        <ButtonContainer>
                                            <BasicButton
                                                onClick={() => dispatch(addToCart(productDetails))}
                                            >
                                                {t("cartFunction.addToCart")}
                                            </BasicButton>
                                        </ButtonContainer>

                                        <form onSubmit={handleSubmit}>
                                            <ReviewWritingContainer>
                                                <Box>
                                                    <Rating
                                                        name="rating"
                                                        value={rating}
                                                        onChange={handleRatingChange}
                                                        size="large"
                                                    />
                                                </Box>
                                                <TextField
                                                    label="Write a Review"
                                                    variant="standard"
                                                    multiline
                                                    value={comment}
                                                    onChange={(e) => setComment(e.target.value)}
                                                    sx={{ width: "90%" }}
                                                    required
                                                />
                                                <Box sx={{ textAlign: 'right', width: '90%' }}>
                                                    <GreenButton type="submit">
                                                        {t("cartFunction.submit")}
                                                    </GreenButton>
                                                </Box>
                                            </ReviewWritingContainer>
                                        </form>
                                    </>
                                }
                                <ReviewWritingContainer>
                                    <Typography variant="h4">{t("cartFunction.reviews")}</Typography>
                                </ReviewWritingContainer>

                                {productDetails.reviews && productDetails.reviews.length > 0 ? (
                                    <ReviewContainer>
                                        {productDetails.reviews.map((review, index) => (
                                            <ReviewCard key={index}>
                                                <ReviewCardDivision>
                                                    <Avatar sx={{ width: "60px", height: "60px", marginRight: "1rem", backgroundColor: generateRandomColor(review._id) }}>
                                                        {String(review.reviewer.name).charAt(0)}
                                                    </Avatar>
                                                    <ReviewDetails>
                                                        <Typography variant="h6">{review.reviewer.name}</Typography>
                                                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>

                                                            <Typography variant="body2">
                                                                {timeAgo(review.date)}
                                                            </Typography>
                                                        </div>
                                                        <Typography variant="subtitle1">{t("cartFunction.rating")}: {review.rating}</Typography>
                                                        <Typography variant="body1">{review.comment}</Typography>
                                                    </ReviewDetails>
                                                    {review.reviewer._id === reviewer &&
                                                        <>
                                                            <IconButton onClick={handleOpenMenu} sx={{ width: "4rem", color: 'inherit', p: 0 }}>
                                                                <MoreVert sx={{ fontSize: "2rem" }} />
                                                            </IconButton>
                                                            <Menu
                                                                id="menu-appbar"
                                                                anchorEl={anchorElMenu}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'left',
                                                                }}
                                                                keepMounted
                                                                transformOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                }}
                                                                open={Boolean(anchorElMenu)}
                                                                onClose={handleCloseMenu}
                                                                onClick={handleCloseMenu}
                                                            >
                                                                <MenuItem onClick={() => {
                                                                    handleCloseMenu()
                                                                }}>
                                                                    <Typography textAlign="center">{t("cartFunction.edit")}</Typography>
                                                                </MenuItem>
                                                                <MenuItem onClick={() => {
                                                                    deleteHandler(review._id)
                                                                    handleCloseMenu()
                                                                }}>
                                                                    <Typography textAlign="center">{t("cartFunction.delete")}</Typography>
                                                                </MenuItem>
                                                            </Menu>
                                                        </>
                                                    }
                                                </ReviewCardDivision>
                                            </ReviewCard>
                                        ))}
                                    </ReviewContainer>
                                )
                                    :
                                    <ReviewWritingContainer>
                                        <Typography variant="h6">{t("cartFunction.reviewNotFound")}</Typography>
                                    </ReviewWritingContainer>
                                }
                            </>
                    }
                </>
            }
            <Popup message={message} setShowPopup={setShowPopup} showPopup={showPopup} />
        </>
    );
};

export default ViewOrder;

const ProductContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 20px;
    justify-content: center;
    align-items: center;
    @media (min-width: 768px) {
        flex-direction: row;
    }
`;

const ProductImage = styled.img`
    max-width: 300px;
    /* width: 50%; */
    margin-bottom: 20px;
`;

const ProductInfo = styled.div`
    display: flex;
    flex-direction: column;
`;

const ProductName = styled.h1`
    font-size: 24px;
`;

const PriceContainer = styled.div`
    display: flex;
    gap: 8px;
    margin-top: 8px;
`;

const PriceMrp = styled.p`
    margin-top: 8px;
    text-decoration: line-through;
    color: #525050;
`;

const PriceCost = styled.h3`
    margin-top: 8px;
`;

const PriceDiscount = styled.p`
    margin-top: 8px;
    color: darkgreen;
`;

const Description = styled.p`
    margin-top: 16px;
`;

const ProductDetails = styled.div`
    margin: 16px;
`;

const ButtonContainer = styled.div`
    margin: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ReviewWritingContainer = styled.div`
    margin: 6rem;
    display: flex;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    flex-direction:column;
`;

const ReviewContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
`;

const ReviewCard = styled(Card)`
  && {
    background-color: white;
    margin-bottom: 2rem;
    padding: 1rem;
  }
`;

const ReviewCardDivision = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const ReviewDetails = styled.div`
  flex: 1;
`;